import WorkspaceThumbnail from "./WorkspaceThumbnail";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PERSONAL_WORKSPACES } from "../queries/personal";
import { get, orderBy, uniqBy } from "lodash";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import * as mst from "@microsoft/teams-js";
import WorkspaceMeetCard from "./WorkspaceMeetCard";

export const MEETING_PRESENTABLE_STORAGE_KEY = "app.meeting.presentable";
export const MEETING_PIN_STORAGE_KEY = "app.meeting.pin";

export default function RightSidebar({ workspace }) {
  const [showPicker, setShowPicker] = useState(false);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [presentableWorkspaces, setPresentableWorkspaces] = useState(
    (prevState = []) => {
      const existing = JSON.parse(
        localStorage.getItem(MEETING_PRESENTABLE_STORAGE_KEY)
      );
      return uniqBy([...(existing || [workspace]), ...prevState], "id");
    }
  );

  const { loading: isRecentDiagramsLoading, data: recentDiagramsResponse } =
    useQuery(GET_PERSONAL_WORKSPACES, {
      errorPolicy: "ignore",
      fetchPolicy: "no-cache",
    });

  const recentDiagrams = useMemo(() => {
    if (!recentDiagramsResponse) return null;
    const privateWorkspaces = get(recentDiagramsResponse, "user.documents");
    return orderBy(privateWorkspaces, ["lastUpdated"], "desc").slice(0, 8);
  }, [recentDiagramsResponse]);

  useEffect(() => {
    console.log(selectedWorkspaces);
  }, [selectedWorkspaces]);

  return (
    <>
      <div className="max-h-[330px] tmod:max-h-[470px] pb-10 tmod:pb-0 overflow-y-scroll">
        {presentableWorkspaces.map((wksp) => (
          <span
            key={wksp.id}
            className="text-creately-primary-xlight inline-block w-full cursor-pointer"
            onClick={() => {
              mst.pages.config.setConfig({
                contentUrl: `${process.env.REACT_APP_CREATELY_APP_HOST}/app/${wksp.id}`,
                entityId: wksp.id,
                suggestedDisplayName: wksp.name || "Creately Workspace",
                websiteUrl: `${process.env.REACT_APP_CREATELY_APP_HOST}/app/${wksp.id}`,
              });
            }}
          >
            {wksp.id === workspace.id ? (
              <WorkspaceThumbnail hightlight={true} workspace={wksp} />
            ) : (
              <WorkspaceThumbnail workspace={wksp} />
            )}
          </span>
        ))}
      </div>
      <div className="relative mt-4 flex items-center justify-between rounded-md mb-2">
        <section className="text-xs text-creately-primary-xdark dark:text-white rounded xs:hidden">
          <p className="mt-1">Workspace added successfully! 🎉 </p>
          <p className="mt-2">
            Click on &lsquo;Add Workspace&rsquo; button to curate and arrange
            the workspaces you want to share.
          </p>
          <p className="mt-2">
            Select a workspace and click 'Share' button below to collaborate
            with your team in real time.
          </p>
        </section>
      </div>

      {/* {isRecentDiagramsLoading && <Spinner text="Loading Recent Workspaces" />} */}

      {!isRecentDiagramsLoading && (
        <section className="w-full absolute left-0 bottom-0">
          <Transition
            as={Fragment}
            show={showPicker}
            enter="transition ease-in-out duration-100 transform"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="transition ease-in-out duration-100 transform"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <Listbox
              as="div"
              value={selectedWorkspaces}
              onChange={setSelectedWorkspaces}
              multiple
              className="bg-creately-neutrals-xlight dark:bg-msteams-dark border border-creately-semantics-green rounded-tr-md rounded-tl-md p-2"
            >
              <Listbox.Button>
                <div className="flex items-center space-x-1 dark:text-creately-neutrals-xxlight">
                  <ChevronDownIcon className="w-4 h-4" />
                  <span className="text-xs">Back to Queue</span>
                </div>
              </Listbox.Button>
              <Listbox.Options
                anchor="bottom"
                as="div"
                className="max-h-[360px] overflow-y-scroll"
                static
              >
                {recentDiagrams.map((doc) => (
                  <Listbox.Option
                    as="div"
                    key={doc.id}
                    value={doc.id}
                    className="data-[focus]:bg-blue-100 mb-2"
                  >
                    {({ selected }) => (
                      <>
                        <div className="flex items-center gap-2">
                          <div className="min-w-[24px]">
                            <span className="inline-block w-5 h-5 border-2 border-creately-primary-dark dark:border-creately-primary-xlight text-creately-primary-xdark dark:text-creately-primary-xlight rounded-sm">
                              {selected ? (
                                <CheckIcon className="w-4 h-4" />
                              ) : null}
                            </span>
                          </div>
                          <WorkspaceMeetCard workspace={doc} selected={false} />
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          </Transition>
          <button
            onClick={() => {
              if (showPicker) {
                setPresentableWorkspaces([
                  ...presentableWorkspaces,
                  ...recentDiagrams.filter((d) =>
                    selectedWorkspaces.includes(d.id)
                  ),
                ]);
                localStorage.setItem(
                  MEETING_PRESENTABLE_STORAGE_KEY,
                  JSON.stringify([
                    ...recentDiagrams.filter((d) =>
                      selectedWorkspaces.includes(d.id)
                    ),
                    ...presentableWorkspaces,
                  ])
                );
                setShowPicker(false);
              } else {
                setShowPicker(true);
              }
            }}
            className="w-full flex items-center justify-center rounded bg-creately-primary-dark hover:bg-creately-primary space-x-2 text-creately-neutrals-xxxlight py-2 my-1"
          >
            <span>
              <PlusIcon className="w-6 h-6" />
            </span>
            {showPicker ? (
              <span>Add Selected</span>
            ) : (
              <span>Add Workspaces</span>
            )}
          </button>
        </section>
      )}
    </>
  );
}
