import { gql } from "@apollo/client";

export const GET_PERSONAL_WORKSPACES = gql`
  query RecentDiagramsQuery {
    user {
      documents(filter: recent) {
        id
        name
        description
        type
        lastUpdated
        privacy
        status
        image {
          svg
        }
        collaborators {
          user {
            id
            role
            email
          }
        }
      }
    }
  }
`;

export const GET_ALL_PERSONAL_WORKSPACES = gql`
  query AllUserWorkspaces($pageNumber: Int, $perPage: Int) {
    user {
      totalDocuments
      documents(filter: all, offset: $pageNumber, limit: $perPage) {
        id
        name
        lastUpdated
        privacy
        status
        image {
          svg
        }
        collaborators {
          user {
            id
            role
          }
        }
      }
    }
  }
`;

export const CREATE_PRIVATE_WORKSPACE = gql`
  mutation CreateTeamWorkspace(
    $documentInput: DocumentInput!
    $privacyLevel: DocumentPrivacy!
  ) {
    documentCreateWithPrivacyLevel(
      document: $documentInput
      source: { type: creately }
      private: true
      privacy: $privacyLevel
    ) {
      id
      name
    }
  }
`;

export const GET_TEAM_WORKSPACES = gql`
  query OrgWorkspacesQuery {
    organization {
      documents(filter: recent) {
        id
        name
        description
        lastUpdated
        privacy
        status
        image {
          svg
        }
        collaborators {
          user {
            id
            role
          }
        }
      }
    }
  }
`;

export const GET_PERSONAL_SHARED_WORKSPACES = gql`
  query SharedDiagramsQuery {
    user {
      documents(filter: shared) {
        id
        name
        description
        lastUpdated
        privacy
        status
        image {
          svg
        }
        collaborators {
          user {
            id
            role
          }
        }
      }
    }
  }
`;

export const FIND_PERSONAL_WORKSPACES = gql`
  query FindWorkspace($name: String!, $orgId: String) {
    searchDocument(name: $name, organizationId: $orgId, type: "organization") {
      id
      name
      lastUpdated
      privacy
      status
      collaborators {
        user {
          id
          role
        }
      }
    }
  }
`;

export const CREATE_WORKSPACE_COPY = gql`
  mutation CreateWorkspaceCopy(
    $fromId: String!
    $name: String!
    $projectId: String!
  ) {
    documentCopy(
      fromId: $fromId
      name: $name
      projectId: $projectId
      private: true
      privacy: team
    ) {
      id
      name
    }
  }
`;

export const DELETE_WORKSPACE = gql`
  mutation DeleteWorkspace($id: String!) {
    documentDelete(id: $id)
  }
`;
