export default function copyText(text) {
  if ("clipboard" in navigator) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("[navigator.clipboard] - Text copied");
      })
      .catch((_) => {
        return tryExecCopy(text);
      })
      .catch((err) => console.error(err.name, err.message));
  } else {
    tryExecCopy();
  }
}

function tryExecCopy(textToCopy) {
  // for old browser compat + safari mobile workaround
  const textArea = document.createElement("textarea");
  textArea.value = textToCopy;
  textArea.style.opacity = 0;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    const success = document.execCommand("copy");
    console.log(
      `[exec copy] - Text copy was ${success ? "successful" : "unsuccessful"}.`
    );
    return Promise.resolve(true);
  } catch (err) {
    console.error(err.name, err.message);
    return Promise.reject(err.message);
  } finally {
    document.body.removeChild(textArea);
  }
}
