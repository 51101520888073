import { useParams, useHistory, useLocation } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_ORG_WORKSPACE,
  FIND_ORG_WORKSPACES,
  GET_ORG_WORKSPACES,
  SET_WORKSPACE_GROUP_PRIVACY,
  SET_WORKSPACE_PRIVACY,
} from "../queries/configure";
import Spinner from "../components/Spinner";
import { RadioGroup, Transition } from "@headlessui/react";
import WorkspaceCard from "../components/WorkspaceCard";
import * as mst from "@microsoft/teams-js";
import { getAppUrl, getNotifications } from "../helpers/utils";
import Layout from "../common/app.layout";

import SearchItem from "../components/SearchItem";
import { useTeams } from "../hooks/useTeams";
import {
  getTeamsWindowContext,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import { get, orderBy } from "lodash";
import WorkspaceSearch from "../components/WorkspaceSearch";
import { BrowserView } from "react-device-detect";
import { useTelemetry } from "../contexts/tacker.context";
import { useAuth } from "../contexts/auth.context";
import { useNotifications } from "../contexts/notifications.context";
import ErrorNotification from "../components/ErrorNotification";
import WorkspaceRenameInput from "../components/WorkspaceRenameInput";

export default function ConfigureWorkspacesPage({ groupId }) {
  const { user } = useAuth();
  const { state } = useLocation();
  const [workspaceId, setWorkspaceId] = useState(state?.workspaceId || "");
  const [pinWorkspace, setPinWorkspace] = useState(null);
  const [toggleRename, setToggleRename] = useState(false);
  const [{ teamsContext }] = useTeams();
  const { folderId } = useParams();
  const isInsideMeeting = useMemo(() => {
    const teamsWindowContext = getTeamsWindowContext(teamsContext);
    return (
      teamsWindowContext === TEAMS_WINDOW_CONTEXT.config.meeting ||
      teamsWindowContext === TEAMS_WINDOW_CONTEXT.config.group
    );
  }, [teamsContext]);
  const { telemetry } = useTelemetry();
  const [documentCreate] = useMutation(CREATE_ORG_WORKSPACE);
  const [setWorkspacePrivacy] = useMutation(SET_WORKSPACE_PRIVACY);
  const [setWorkspaceGroupPrivacy] = useMutation(SET_WORKSPACE_GROUP_PRIVACY);
  const { show } = useNotifications();
  const history = useHistory();

  const { loading, data: orgDiagramsResponse } = useQuery(GET_ORG_WORKSPACES, {
    variables: {
      pageNumber: 1,
      perPage: 12,
      order: "dateDesc",
      groupId: groupId || "",
    },
    fetchPolicy: "network-only",
  });

  const workspaceInfo = useMemo(() => {
    if (!workspaceId) return null;
    return get(orgDiagramsResponse, "team.documents", []).find(
      (doc) => doc.id === workspaceId
    );
  }, [workspaceId, orgDiagramsResponse]);

  useEffect(() => {
    if (!teamsContext) return;
    if (
      teamsContext.app?.theme === "dark" ||
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.classList.add("dark");
    }

    return () => document.documentElement.classList.remove("dark");
  }, [teamsContext]);

  const teamWorkspaces = useMemo(() => {
    if (!orgDiagramsResponse) return null;
    return orderBy(
      get(orgDiagramsResponse, "team.documents", []),
      ["lastUpdated"],
      "desc"
    );
  }, [orgDiagramsResponse]);

  useEffect(() => {
    mst.pages.config.registerOnSaveHandler(async function (saveEvent) {
      try {
        if (groupId) {
          // groupId is null in meeting scope
          await setWorkspaceGroupPrivacy({
            variables: { workspaceId, groupId },
          });
        } else {
          await setWorkspacePrivacy({ variables: { workspaceId } });
        }
        var tabUrl = getAppUrl(`/app/${workspaceId}`);
        mst.pages.config.setConfig({
          contentUrl: tabUrl, // Mandatory parameter
          entityId: tabUrl, // Mandatory parameter
          websiteUrl: `${process.env.REACT_APP_NUCLEUS_HOST}/d/${workspaceId}/edit`,
          suggestedDisplayName:
            workspaceInfo?.name || pinWorkspace?.name || "Creately Workspace",
        });
        if (isInsideMeeting) {
          telemetry.track("workspace.meeting.pin");
        } else {
          telemetry.track("workspace.channel.pin", {
            value1Type: "Pinned Location",
            value1: "Workspace",
          });
        }
        saveEvent.notifySuccess();
      } catch (err) {
        saveEvent.notifyFailure(err.message);
      }
    });

    if (workspaceId !== pinWorkspace?.id) {
      setPinWorkspace(null);
    }
    mst.pages.config.setValidityState(!!workspaceId);
  }, [
    workspaceId,
    pinWorkspace,
    workspaceInfo,
    isInsideMeeting,
    telemetry,
    setWorkspacePrivacy,
    setWorkspaceGroupPrivacy,
    groupId,
  ]);

  // Only used for meetings
  async function createWorkspace(name = undefined) {
    const {
      data: { documentCreateWithPrivacyLevel: document },
    } = await documentCreate({
      variables: {
        documentInput: {
          name: name || "Creately Workspace",
          projectId: folderId,
        },
      },
      refetchQueries: [GET_ORG_WORKSPACES],
      onError: (error) => {
        const errors = getNotifications(error);
        errors.forEach(([title, message, type]) =>
          show(
            type === "custom" ? (
              <ErrorNotification error={{ title, message }} type={type} />
            ) : (
              message
            ),
            type
          )
        );
      },
    });
    setWorkspaceId(document.id);
    setPinWorkspace({ ...document, lastUpdated: Date.now() });
    return document.id;
  }

  function handleCreateClick(workspaceName) {
    if (isInsideMeeting) {
      // Create workspace & open
      createWorkspace(workspaceName).then(() => {
        telemetry.track("workspace.create", {
          value1Type: "Location",
          value1: "Typical",
          value2Type: "App Context",
          value2: "Meeting",
        });
        setToggleRename(false);
      });
    } else {
      history.push({
        pathname: `/configure`,
        state: {
          starter: true,
        },
      });
    }
  }

  return (
    <Layout bgClass="bg-white dark:bg-transparent">
      <div className="overflow-x-auto">
        {teamWorkspaces ? (
          <div>
            <div className="flex gap-4 z-20">
              <div className="flex-1 rounded-md bg-gray-50 dark:bg-zinc-800 px-3 py-1">
                <WorkspaceSearch
                  gqlQuery={FIND_ORG_WORKSPACES}
                  gqlVars={user ? { orgId: user?.organization?.id } : null}
                  filterFn={(wksp) =>
                    ["team", "teamLink"].includes(wksp.privacy)
                  }
                >
                  {({ workspaces: searchResults, clear }) =>
                    searchResults.map((wksp) => (
                      <SearchItem item={wksp} key={wksp.id}>
                        <button
                          onClick={() => {
                            setWorkspaceId(wksp.id);
                            setPinWorkspace(wksp);
                            clear();
                          }}
                          className="text-sm font-semibold text-white bg-creately-primary-dark hover:bg-opacity-90 transition-colors rounded py-1 px-2 shadow"
                        >
                          Select Workspace
                        </button>
                      </SearchItem>
                    ))
                  }
                </WorkspaceSearch>
              </div>

              <BrowserView>
                <button
                  className="flex gap-1 text-white items-center bg-creately-primary-dark border disabled:bg-creately-neutrals-dark disabled:text-creately-primary-xlight py-2 px-4 rounded w-full"
                  onClick={() => setToggleRename(!toggleRename)}
                  disabled={toggleRename}
                >
                  <PlusIcon className="w-3 h-3" />
                  <span className="text-sm font-semibold">New Workspace</span>
                </button>
              </BrowserView>
            </div>

            <Transition
              as="div"
              className="z-10"
              show={toggleRename}
              enter="transition ease-in-out duration-75 transform opacity"
              enterFrom="-translate-y-full opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transition ease-in-out duration-75 transform opacity"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="-translate-y-full opacity-0"
            >
              <WorkspaceRenameInput
                onSubmit={(input) => handleCreateClick(input.workspaceName)}
                onCancel={() => setToggleRename(false)}
                defaultInput="Untitled Workspace"
                placeholder={"Workspace name"}
              />
            </Transition>
          </div>
        ) : null}

        {loading ? (
          <Spinner
            className="absolute top-1/2 left-1/2"
            text="Loading Workspaces"
          />
        ) : null}

        {teamWorkspaces ? (
          <>
            <h3 className="font-semibold text-sm mt-6 mb-2 text-gray-900 dark:text-creately-neutrals-xlight dark:text-opacity-70">
              Recent Workspaces
            </h3>
            <RadioGroup value={workspaceId} onChange={setWorkspaceId}>
              <div className="grid gap-4 grid-cols-1 xs:grid-cols-2">
                {teamWorkspaces.map((doc) => (
                  <RadioGroup.Option value={doc.id} key={doc.id}>
                    {({ checked }) => (
                      <WorkspaceCard workspace={doc} selected={checked} />
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </>
        ) : null}
      </div>
    </Layout>
  );
}
