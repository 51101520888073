import { dateSince, getPlural } from "../helpers/utils";
import { CheckIcon } from "@heroicons/react/24/outline";
import DocumentStatusTag from "./DocumentStatusTag";

export default function WorkspaceMeetCard(props) {
  const { workspace, selected = false, loading = false } = props;

  return (
    <div className="w-full relative">
      <div className="flex shadow-md rounded overflow-hidden p-1">
        <div className="flex flex-col gap-1 w-full">
          <div className="flex items-center text-ellipsis">
            <h5 className="text-sm text-gray-900 dark:text-creately-neutrals-xxxlight dark:hover:text-creately-primary-xlight font-semibold tracking-tight truncate">
              {workspace.name}
            </h5>
            <DocumentStatusTag status={workspace.status} />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h6 className="flex items-center text-gray-400 dark:text-creately-neutrals-xlight dark:text-opacity-50">
                <span className="text-xs font-medium">
                  {`Edited ${dateSince(workspace.lastUpdated)}`}
                </span>
              </h6>
            </div>
            <div className="items-center hidden sm:block">
              <h6 className="flex items-center text-gray-400 dark:text-opacity-50">
                <span className="text-xs font-medium">
                  {getPlural(workspace.collaborators.length, "Collaborator")}
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
      {selected && (
        <span className="inline-block absolute top-1 right-1 z-10 text-creately-primary-dark dark:text-creately-neutrals-light">
          <CheckIcon className="w-6 h-6 mx-1" />
        </span>
      )}
      {loading && (
        <span className="inline-block absolute top-2 right-2 z-10 w-4 h-4 border-2 border-creately-theme-skin spinner"></span>
      )}
    </div>
  );
}
