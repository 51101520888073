import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import useAutoSelect from "../hooks/useAutoSelect";

const WorkspaceNameSchema = Yup.object().shape({
  workspaceName: Yup.string()
    .min(1, "Name is too short")
    .max(80, "Name is too long")
    .required("Name shouldn't be empty"),
});

export default function WorkspaceRenameInput({
  onSubmit,
  onCancel,
  defaultInput = "Enter value",
  placeholder,
}) {
  const nameInput = useAutoSelect();

  return (
    <div className="py-1">
      <Formik
        initialValues={{
          workspaceName: defaultInput,
        }}
        validationSchema={WorkspaceNameSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm }) => (
          <Form>
            <div className="flex items-center gap-2">
              <Field name="workspaceName">
                {({ field, form: { touched, errors }, meta }) => (
                  <div className="flex-grow relative">
                    <div className="dark:bg-msteams-dark dark:text-creately-theme-black rounded-sm p-1">
                      <input
                        type="text"
                        className="w-full text-sm py-2 px-3 bg-gray-50 focus:border-creately-primary-dark dark:bg-creately-theme-black dark:text-gray-50 rounded border dark:border-gray-800 outline-none"
                        autoComplete="off"
                        placeholder={placeholder}
                        {...field}
                        ref={nameInput}
                      />
                    </div>
                    {meta.touched && meta.error && (
                      <p className="absolute left-2 bottom-0 translate-y-full text-creately-semantics-red-light text-xs mt-1">
                        {meta.error}
                      </p>
                    )}
                  </div>
                )}
              </Field>

              <div className="flex items-center gap-1">
                <button
                  type="button"
                  className="text-white items-center bg-creately-primary-dark border border-creately-primary-dark py-1 px-2 rounded text-sm font-semibold"
                  onClick={submitForm}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="text-creately-primary-xdark items-center bg-creately-neutrals-xxxlight border border-creately-neutrals-light py-1 px-2 rounded text-sm font-semibold"
                  onClick={(event) => {
                    event.stopPropagation();
                    onCancel();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
