import { useMutation, useQuery } from "@apollo/client";
import { RadioGroup, Transition } from "@headlessui/react";
import { FolderIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import FolderCard from "../components/FolderCard";
import Spinner from "../components/Spinner";
import * as mst from "@microsoft/teams-js";
import { getAppUrl, getNotifications } from "../helpers/utils";
import {
  CREATE_ORG_FOLDER,
  GET_ORG_FOLDERS,
  SET_FOLDER_GROUP_PRIVACY,
  SET_FOLDER_PRIVACY,
} from "../queries/configure";
import ListFilter from "../components/ListFilter";
import Layout from "../common/app.layout";
import { useTeams } from "../hooks/useTeams";
import { BrowserView } from "react-device-detect";
import { useTelemetry } from "../contexts/tacker.context";
import { useNotifications } from "../contexts/notifications.context";
import ErrorNotification from "../components/ErrorNotification";
import WorkspaceRenameInput from "../components/WorkspaceRenameInput";

export default function ConfigureFolderPage({ groupId }) {
  const [folderId, setFolderId] = useState("");
  const [pinFolder, setPinFolder] = useState(null);
  const [toggleRename, setToggleRename] = useState(false);
  const [{ teamsContext }] = useTeams();
  const { telemetry } = useTelemetry();
  const { show } = useNotifications();

  const { loading, data: orgFoldersResponse } = useQuery(GET_ORG_FOLDERS, {
    variables: { order: "dateDesc", groupId: groupId || "" },
    fetchPolicy: "network-only",
  });

  const folderInfo = useMemo(() => {
    if (!folderId) return null;
    return get(orgFoldersResponse, "team.folders", []).find(
      (proj) => proj.id === folderId
    );
  }, [folderId, orgFoldersResponse]);

  const [folderCreate] = useMutation(CREATE_ORG_FOLDER);
  const [setFolderPrivacy] = useMutation(SET_FOLDER_PRIVACY);
  const [setFolderGroupPrivacy] = useMutation(SET_FOLDER_GROUP_PRIVACY);

  async function createFolder(folderName = undefined) {
    const {
      data: { folderCreate: document },
    } = await folderCreate({
      errorPolicy: "none",
      variables: {
        folderName: folderName || "Untitled Folder",
      },
      refetchQueries: [GET_ORG_FOLDERS],
      onError: (error) => {
        const errors = getNotifications(error);
        errors.forEach(([title, message, type]) =>
          show(
            type === "custom" ? (
              <ErrorNotification error={{ title, message }} type={type} />
            ) : (
              message
            ),
            type
          )
        );
      },
    });
    if (groupId) {
      await setFolderGroupPrivacy({
        variables: { folderId: document.id, groupId },
      });
    } else {
      await setFolderPrivacy({ variables: { folderId: document.id } });
    }
    setFolderId(document.id);
    setPinFolder(document);
  }

  useEffect(() => {
    mst.pages.config.registerOnSaveHandler(function (saveEvent) {
      var tabUrl = getAppUrl(`/channel/${folderId}`);
      mst.pages.config.setConfig({
        contentUrl: tabUrl, // Mandatory parameter
        entityId: tabUrl, // Mandatory parameter
        suggestedDisplayName:
          folderInfo?.name || pinFolder?.name || "Creately Diagram",
      });
      telemetry.track("workspace.channel.pin", {
        value1Type: "Pinned Location",
        value1: "Folder",
      });
      saveEvent.notifySuccess();
    });

    if (folderId !== pinFolder?.id) {
      setPinFolder(null);
    }

    mst.pages.config.setValidityState(!!folderId);
  }, [folderId, pinFolder, folderInfo, telemetry]);

  useEffect(() => {
    if (!teamsContext) return;
    if (
      teamsContext.app?.theme === "dark" ||
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.classList.add("dark");
    }

    return () => document.documentElement.classList.remove("dark");
  }, [teamsContext]);

  return (
    <Layout bgClass="bg-white dark:bg-transparent">
      <div className="overflow-x-auto h-screen">
        {orgFoldersResponse ? (
          <div>
            <div className="flex gap-4 z-20">
              <div className="flex-1 rounded-md bg-gray-50 dark:bg-zinc-800 px-3 py-2">
                <ListFilter
                  listItems={get(orgFoldersResponse, "team.folders", [])}
                >
                  {({ filteredItems: searchResults, clear }) =>
                    searchResults.map((folder) => (
                      <div className="group flex items-start justify-between bg-gray-50 hover:bg-gray-100 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:border-none px-1 py-2 rounded-md border border-gray-100">
                        <div className="flex space-x-2">
                          <FolderIcon className="w-6 h-6 dark:text-creately-theme-black" />
                          <h5 className="text-gray-900 dark:text-gray-300 dark:text-opacity-70 font-semibold tracking-tight">
                            {folder.name}
                          </h5>
                        </div>
                        <div className="hidden self-start group-hover:block">
                          <button
                            onClick={() => {
                              setFolderId(folder.id);
                              setPinFolder(folder);
                              clear();
                            }}
                            className="text-sm font-semibold text-white bg-creately-primary-dark hover:bg-opacity-90 transition-colors rounded py-1 px-2 shadow"
                          >
                            Select Folder
                          </button>
                        </div>
                      </div>
                    ))
                  }
                </ListFilter>
              </div>
              <BrowserView>
                <button
                  className="flex gap-1 text-white items-center bg-creately-primary-dark border disabled:bg-creately-neutrals-dark disabled:text-creately-primary-xlight py-2 px-4 rounded"
                  onClick={() => setToggleRename(true)}
                  disabled={toggleRename}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </span>
                  <h4 className="text-sm font-semibold">New Folder</h4>
                </button>
              </BrowserView>
            </div>

            <Transition
              as="div"
              className="z-10"
              show={toggleRename}
              enter="transition ease-in-out duration-75 transform opacity"
              enterFrom="-translate-y-full opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transition ease-in-out duration-75 transform opacity"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="-translate-y-full opacity-0"
            >
              <WorkspaceRenameInput
                onSubmit={(input) => createFolder(input.workspaceName)}
                onCancel={() => setToggleRename(false)}
                defaultInput="Untitled Folder"
                placeholder={"Folder name"}
              />
            </Transition>
          </div>
        ) : null}

        {loading ? (
          <Spinner
            className="absolute top-1/2 left-1/2"
            text="Loading Folders"
          />
        ) : null}

        {orgFoldersResponse ? (
          <>
            <h3 className="font-semibold text-sm mt-6 mb-2 text-gray-900 dark:text-creately-neutrals-xlight dark:text-opacity-70">
              Recent Folders
            </h3>
            <RadioGroup value={folderId} onChange={setFolderId}>
              <div className="grid gap-4 grid-cols-1 xs:grid-cols-2">
                {get(orgFoldersResponse, "team.folders", []).map((folder) => (
                  <RadioGroup.Option value={folder.id} key={folder.id}>
                    {({ checked }) => (
                      <FolderCard folder={folder} selected={checked} />
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </>
        ) : null}
      </div>
    </Layout>
  );
}
