import { PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

export default function TemplateThumbnail({ template, onClick, selected }) {
  return (
    <div
      onClick={() => (onClick ? onClick(template.id) : null)}
      className="flex flex-col items-center"
    >
      <div
        className={classNames({
          "flex items-center justify-center rounded border": true,
          " bg-creately-primary-xxlight bg-opacity-10 dark:bg-opacity-60 border-creately-neutrals-light dark:border-creately-neutrals-xxdark dark:hover:border-creately-neutrals-xlight hover:border-creately-primary-xdark":
            !selected,
          " bg-creately-neutrals-xlight dark:bg-zinc-800 border-creately-primary-dark dark:border-creately-neutrals-light":
            selected,
        })}
      >
        {template.thumbnailURL && (
          <img
            src={template.thumbnailURL}
            alt={`Create a new workspace from ${template.name} template`}
            className="block p-3 w-28 h-24 lg:w-64 lg:h-52"
          />
        )}
        {!template.thumbnailURL && template.name === "Blank" && (
          <div className="flex items-center justify-center p-3 w-28 h-24 sm:w-64 sm:h-52">
            <PlusIcon className="w-10 h-10 dark:text-white" />
          </div>
        )}
      </div>
      <h5 className="text-sm text-gray-900 text-center dark:text-creately-neutrals-xxlight tracking-tight break-normal">
        {template.name}
      </h5>
    </div>
  );
}
