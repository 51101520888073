import { useRef, useEffect } from "react";

const useAutoSelect = () => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  return inputRef;
};

export default useAutoSelect;
