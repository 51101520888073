import React, { useState, useEffect } from "react";
import { joinSocket } from "../helpers/socket";
import { useTeams } from "../hooks/useTeams";

export default function SocketConnector({ onAddShape, onAddTemplate }) {
  const [isConnected, setIsConnected] = useState(false);
  const [{ teamsContext, isInitializing }] = useTeams();

  useEffect(() => {
    if (isInitializing || !teamsContext?.channel || !teamsContext?.team) {
      return;
    }
    const channelSocket = joinSocket(
      teamsContext.channel.id || teamsContext.team.internalId
    );

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onBotUxEvent(value) {
      console.log(value);
      if (value.defId) {
        onAddShape(value.defId, value.editor);
      } else if (value.templateId) {
        onAddTemplate(value.templateId, value.editor);
      }
    }

    channelSocket.on("connect", onConnect);
    channelSocket.on("disconnect", onDisconnect);
    channelSocket.on("botux", onBotUxEvent);

    return () => {
      channelSocket.off("connect", onConnect);
      channelSocket.off("disconnect", onDisconnect);
      channelSocket.off("botux", onBotUxEvent);
      channelSocket.disconnect();
    };
  }, [onAddShape, onAddTemplate, isInitializing, teamsContext]);

  return (
    <div id="socket-connector">
      <span className="hidden">
        Socket Status: {isConnected ? "Active" : "Inactive"}
      </span>
      {/* <span className="hidden">Socket ID: {socket.id}</span> */}
    </div>
  );
}
